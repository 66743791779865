import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import advancedFormat from 'dayjs/plugin/advancedFormat'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)

const DateUtils = {
  getWeekday: (daysFromToday) => {
    const diff = 1000 * 60 * 60 * 24 * (daysFromToday - 1)
    var date = new Date(Date.now() - diff)
    return date.toLocaleString('default', { weekday: 'short' })
  },

  getWeekdayLong: (daysFromToday) => {
    const diff = 1000 * 60 * 60 * 24 * (daysFromToday - 1)
    var date = new Date(Date.now() - diff)
    return date.toLocaleString('default', { weekday: 'long' })
  },

  getDayBounds: (daysAgo) => {
    const dayStart = dayjs()
      .tz(dayjs.tz.guess())
      .subtract(daysAgo, 'days')
      .startOf('day')
    const dayEnd = dayjs()
      .tz(dayjs.tz.guess())
      .subtract(daysAgo, 'days')
      .endOf('day')
    const range = [dayStart.unix() * 1000, dayEnd.unix() * 1000]
    console.log({ range })
    return range
  },
}

export default DateUtils
